table,
th,
td {
  /* border: 1px solid #cc0000; */
  /* border-collapse: collapse; */
  /* padding: 10px; */
  border-spacing: 15px;
}

.agenda-table {
  width: 100%;
  margin-top: 20px;
}
.agenda-break {
  background-color: #f9eaea;
  padding: 12px;
}
.agenda-break-short {
  text-align: center;
  font-size: 20px;
}
.agenda-location1 {
  background-color: #f2f2f2;
  padding: 12px;
  padding-left: 36px;
}
.agenda-location1 p,
.agenda-location2 p {
  font-size: 20px;
  color: #808080;
}
.agenda-location2 {
  background-color: #f9eaea;
  padding: 12px;
  padding-left: 36px;
}
.agenda-time {
  background-color: #f2f2f2;
  padding: 12px;
  vertical-align: baseline;
  padding-top: 30px;
  min-width: 116px;
}
.agenda-time p {
  font-size: 20px;
  color: #808080;
  text-align: left;
}
.agenda-title-hr {
  width: 10%;
  margin: 0;
  background: #cc0000;
  height: 2px;
  border: none;
}
.agenda-day1-img {
  display: flex;
  align-items: flex-end;
  justify-content: right;
}
.agenda-day1-img img {
  width: 50%;
}
.agenda-day1-title-hr {
  width: 5%;
  margin: 0;
  background: #cc0000;
  height: 2px;
  border: none;
}

.agenda-day3-title-hr {
  width: 2%;
  margin: 0;
  background: #cc0000;
  height: 3px;
  border: none;
}
