body {
  margin: 0;
  font-family: "HelveticaForTarget", "Helvetica" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/HelveticaForTarget_n1.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: italic;
  font-weight: 100;
  src: url("./assets/fonts/HelveticaForTarget_i1.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/HelveticaForTarget_n2.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/HelveticaForTarget_i2.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: normal;
  font-weight: normal;
  /* 400 */
  src: url("./assets/fonts/HelveticaForTarget_n4.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: italic;
  font-weight: normal;
  /* 400 */
  src: url("./assets/fonts/HelveticaForTarget_i4.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/HelveticaForTarget_n5.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/HelveticaForTarget_i5.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: normal;
  font-weight: bold;
  /* 700 */
  src: url("./assets/fonts/HelveticaForTarget_n7.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: italic;
  font-weight: bold;
  /* 700 */
  src: url("./assets/fonts/HelveticaForTaget_i7.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/HelveticaForTarget_n8.woff2") format("woff2");
}

@font-face {
  font-family: HelveticaForTarget;
  font-style: italic;
  font-weight: 900;
  src: url("./assets/fonts/HelveticaForTarget_i8.woff2") format("woff2");
}

@font-face {
  font-family: Blindsh;
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Blindsh-amLm.ttf") format("woff2");
}

.flip-countdown .flip-countdown-piece .flip-countdown-card,
.flip-countdown-card-sec {
  font-family: Blindsh;
  box-shadow: none !important;
}

.flip-countdown-piece {
  display: flex !important;
  flex-direction: column-reverse;
  align-items: center;
  gap: 10px;
}
.flip-countdown {
  display: flex !important;
  align-items: center;
  gap: 20px;
}

.flip-countdown.theme-light {
  --text-color: #000 !important;
  --text-bottom-color: #000 !important;
  --background-color: #efe8dd !important;
  --background-bottom-color: #efe8dd !important;
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top,
.card__bottom {
  border-bottom: none !important;
  color: #000 !important;
}

.flip-countdown.size-medium .flip-countdown-piece .flip-countdown-title {
  font-size: 24px !important;
  color: #000;
}
.flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
  font-size: 150px !important;
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top,
.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom,
.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before,
.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::after {
  padding: 0 !important;
  border-radius: 0 !important;
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom {
  height: 50px !important;
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 767px) {
  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 50px !important;
  }
  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-title {
    font-size: 12px !important;
    color: #000;
  }
  .flip-countdown,
  .flip-countdown-piece {
    gap: 5px !important;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom {
    height: 18px !important;
  }
}

/* Tablet Devices */
@media (min-width: 768px) and (max-width: 1022px) {
  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 80px !important;
  }
  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-title {
    font-size: 16px !important;
    color: #000;
  }
  .flip-countdown,
  .flip-countdown-piece {
    gap: 10px !important;
  }
  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom {
    height: 28px !important;
  }
}

@media (min-width: 1240px) and (max-width: 1340px) {
  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 125px !important;
  }
}

@media (min-width: 1023px) and (max-width: 1100px) {
  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 85px !important;
  }
}

.clues {
  padding: 0 2% !important;
}
.clue {
  font-family: HelveticaForTarget;
}

.direction {
  text-align: left;
}
.header {
  font-family: HelveticaForTarget;
}

/* AR device default pop up styles */

.a-dialog-button .a-dialog-deny-button {
  background-color: #cc0000 !important;
  color: #fff !important;
}

.a-dialog-button .a-dialog-allow-button {
  background-color: #cc0000 !important;
  color: #fff !important;
  border: 1px solid !important;
}
